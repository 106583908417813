<template>
  <div class="baubilder">
    <div class="row">
      <div class="flex xs12">
        <va-card :title="$t('baubilder.title')">
          <gallery :images="images" :index="index" @close="index = null"></gallery>
          <div
            class="image"
            v-for="(image, imageIndex) in images"
            :key="imageIndex"
            @click="index = imageIndex"
            :style="{ backgroundImage: 'url(' + image + ')', width: '300px', height: '200px' }"
          ></div>
        </va-card>
      </div>
    </div>
  </div>
</template>

<!--
https://github.com/RobinCK/vue-gallery
-->

<script>
import VueGallery from 'vue-gallery'

export default {
  name: 'baubilder',
  components: {
    gallery: VueGallery,
  },
  data () {
    this.getImages()
    return {
      images: [
      ],
      visible: false,
      index: null,
    }
  },
  methods: {
    getImages () {
      this.$srvconn.sendMessage('getImagesLst')
        .then(response => {
          if (response.msgType === 'getImagesLst') {
            this.images = []
            response.userData.images.forEach((img) => {
              this.images.push('https://bau.mbgft.de/BauBilder_GyUZiPN5KTQTeJaUQ/' + img)
            })
          }
          // console.log(response)
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped>
  .image {
    float: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid #ebebeb;
    margin: 5px;
  }
</style>
